html {
  scroll-behavior: smooth;
  background-color: rgb(28, 82, 84);
}
// /* Hide the scrollbar */
// body::-webkit-scrollbar {
//   width: 0.5em;
//   background-color: blue;
// }

// /* Track */
// body::-webkit-scrollbar-track {
//   background: red;
// }

// /* Handle */
// body::-webkit-scrollbar-thumb {
//   background: blue;
// }

// /* Handle on hover */
// body::-webkit-scrollbar-thumb:hover {
//   background: transparent;
// }
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  text-decoration: none;
  list-style: none;
  background-color: rgb(28, 82, 84);
  -webkit-font-smoothing: antialiased;
}

body img {
  object-fit: cover;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.intersect {
  height: 1px;
}

.intersectAmen {
  position: absolute;
  bottom: 35%;
  left: 0;
}

.intersect-faq {
  position: absolute;
  top: 30%;
  left: 0;
}
.intersect-home {
  position: absolute;
  bottom: 0;
}

.intersect-city {
  position: absolute;
  bottom: 30%;
  left: 0;
}

.mediaNavPopUp {
  display: none;

}
  
.App {
  width: 100%;
  transition: .2s;
  // background-color: rgb(123, 156, 158);
  // background-color: rgb(12, 65, 68);
  background-color: rgb(28, 82, 84);
  height: 2000%;
  -webkit-font-smoothing: antialiased;
}

.navbar {
  width: 94%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center; /* Centers the content vertically */
  height: 17vh;
    z-index: 100;
    position: relative;
}

.WordMark {
  margin-left:0; ;
  color: white;
  width: 30%;
}
.WordMark h1 {
  color: white;
  font-size: 250%;
}
.WordMark h1 a {
  color: white;
  text-decoration: none;
}

.nav-right-section {
  width: 60%;
  margin-left: 20%;

}
.nav-right-section ul {
  display: flex;
  justify-content: center; /* Centers the list horizontally */
  list-style: none; /* Removes the default list appearance */
}

.nav-right-section ul li {
  margin: 0 4%;
}

.nav-right-section ul li a {
  text-decoration: none;
  color: white;
  font-size: 120%;
  transition: .5s;
}
.nav-right-section ul li .clicked {
  text-decoration: none;
  border-bottom: 1.5px solid white;
  color: white;
  font-size: 120%;
  transition: .5s;
}
.nav-right-section ul li a:hover {
  text-decoration: none;
  border-bottom: 1.5px solid rgb(255,215,0);
  // color: rgb(5, 5, 5);
  color: 	rgb(255,215,0);
  // font-size: 130%;
}

.social-links {
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
 right: 2%;
 position: relative;
}

.link {
  flex: 1; /* Distributes the available space equally among the child elements */
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  margin: 0 13%;
}
.link img {
  width: 20px; /* Set the width of the images to 20 pixels */
  height: 20px;

}
.request-btn {
  margin-left: 0%;
  width: 18%;
  position: relative;
  right: 0;
}
.request-btn a {
padding: 17px 25px;
background-color:	rgb(255,215,0);
color: rgb(0, 0, 0);
  // color: 	rgb(255,215,0);
position: relative;
left: 10px;
border: none;
border-radius: 25px;
text-decoration: none;
transition: .25s;
}
.request-btn a:hover {
color: white;
padding: 17px 25px;
background-color: rgb(0, 0, 0);

}


// NAVBAR ABOVE ^^^^^^^^^^ /////////////////////////////////////////////////////////////////////




///// LANDING CONTENT !!!!  ///////////////////////////////////////////////// ///////////
.Landing-content {
  width: 94%;
  margin: auto;
  color: white;

}

.Landing-content {
  width: 94%;
  margin: auto;
  color: white;

}

.Landing-content title-div  {
width: 100%;

}
.Landing-content .title-div h1 {
font-size: 70px;
line-height: .9;
}


.Landing-content .landing-image {
 width: 100%;
 height: 50%;
 height: 800px; /* Set the desired height for the container */
 overflow: hidden; /* Hide the overflowing content */
 border-radius: 20px;
}
.Landing-content .landing-image img{
 width: 100%;
 display: block;
 width: 100%; /* Ensure the image fills the container horizontally */
 object-fit: cover; /* Crop the image to cover the container */
 position: relative;
 bottom: 150px ;
 border-radius: 20px;
}


////////@at-root
/// 

.Comfort-section {
  width: 94%;
  margin: auto;
  display: flex;
  margin-top: 13%;
  position: relative;
}

.Comfort-section .comfort-img {
  width: 100%;
  margin: auto;
  transition: .5s;
  opacity: 0;
  transform: translateX(-50px);
  border-radius: 20px;
}


.Comfort-section .comfort-img img{
  width: 100%;
  margin: auto;
  border-radius: 20px;
}
.Comfort-section .comfort-info {
  width: 90%;
  margin: auto;
  transition: .75s;
  opacity: 0;
  transform: translateX(50px);
}

.Comfort-section .comfort-info h2 {
color: rgb(255, 255, 255);
font-size: 260%;
width: 90%;
margin: auto;
line-height: 1.1;
}
.Comfort-section .comfort-info p {
color: rgb(255, 255, 255);
font-size: 190%;
width: 90%;
margin: auto;
margin-top: 5%;
font-size: 170%;
font-weight: 500;
line-height: 2;
}
.Comfort-section .comfort-info p span {
color: 	rgb(255,215,0);
font-weight: 770;
}

.Comfort-section.active .comfort-img {
  transform: translateX(0px);
  opacity: 1;
}

.Comfort-section.active .comfort-info {
  transform: translateX(0px);
  opacity: 1;
}

.City-section {
  width: 94%;
  margin: auto;
  display: flex;
  margin-top: 13%;
  position: relative;
}

.City-section .city-img {
  width: 100%;
  margin: auto;
  position: relative; /* Add relative positioning to the container */
  transform: translateX(50px);
  opacity: 0;
  transition: .5s;
}
.City-section .city-imgm {
  width: 100%;
  margin: auto;
  position: relative; /* Add relative positioning to the container */
  transform: translateX(50px);
  opacity: 0;
  transition: .5s;
  display: none;
}

.City-section.active .city-img {
  transform: translateX(0px);
  opacity: 1;
}

.City-section .city-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05); /* Set the background color with transparency */
}

.City-section .city-img img {
  width: 100%;
  margin: auto;
  border-radius: 20px;
  position: relative; /* Add relative positioning to the image */
}
.City-section .city-info {
  width: 65%;
  margin: auto;
  transform: translateX(-50px);
  opacity: 0;
  transition: .5s;
}

.City-section.active .city-info {
  transform: translateX(0px);
  opacity: 1;
}
.City-section .city-info h2 {
  color: rgb(255, 255, 255);
  color: 	rgb(255,215,0);
font-size: 300%;
width: 90%;
margin: auto;
}
.City-section .city-info p {
  color: rgb(255, 255, 255);
font-size: 180%;
width: 90%;
margin: auto;
margin-top: 5%;
font-weight: 500;
}

// AMENITIES section !!!! ///////////////////////////////////////

.Amenities-section {
  width: 94%;
  height: 110vh;
  margin: auto;
  margin-top: 15%;
  display: flex;
  position: relative;
}
.Amenities-section .amenities-img {
  width: 75%;
  margin: auto;
  height: 700px; /* Set the desired height for the container */
  overflow: hidden; /* Hide the overflowing content */
  margin-right: 15%;
  position: absolute;
  opacity: 0;
  transform: translateX(-50px);
  transition: .5s;
}

.Amenities-section.active .amenities-img {
  transform: translateX(0px);
  opacity: 1;
  border-radius: 20px;
}
.Amenities-section .amenities-img img {
  width: 100%;
  margin: auto;
  position: relative;
  bottom: 15%;
  top: 5px;
  left: 3px;
  border-radius: 20px;
}

.Amenities-section .amenities-content-box {
  background-color: white;
  width: 583px;
  height: 250px;
  position: relative;
  top: 150px;
  left: 570px;
  padding: 4.5%;
  line-height: .8;
  opacity: 0;
  transform: translateX(50px);
  transition: .75s;
  border-radius: 20px;
}

.Amenities-section.active .amenities-content-box {
  transform: translateX(0px);
  opacity: 1;
}
.Amenities-section .amenities-content-box h1 {
  font-size: 300%;

}
.Amenities-section .amenities-content-box h3 {
  font-size: 200%;
  font-weight: 300;
}
.Amenities-section .amenities-content-box a button {
  font-size: 120%;
  padding: 20px 30px;
  background-color: rgb(83, 81, 81);
  color: white;
  border: none;
  border-radius: 20px;

  padding: 17px 25px;
background-color:	rgb(255,215,0);
color: rgb(0, 0, 0);
  // color: 	rgb(255,215,0);
position: relative;
// left: 0px;
border: none;
border-radius: 25px;
text-decoration: none;
transition: .25s;
}
.Amenities-section .amenities-content-box a button:hover {
  color: white;
  padding: 17px 25px;
  background-color: rgb(0, 0, 0);
  
  }

// Footer!! // ////////////////////////////////////@at-root

.Footer {
  width: 94%;
  margin: auto;
  height: 25vh;
}
.Footer h1 {
 font-size: 300%;
 color: white;
 color: 	rgb(255,215,0);
}

.footer-social-links {
  display: flex;
  justify-content: left; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
 right: 0%;
 position: relative;
 width: 20%;
 top: 5%;
}

.footer-social-links .link {
  flex: 0; /* Distributes the available space equally among the child elements */
  display: flex;
  justify-content: left; /* Centers the content horizontally */
  margin: 0 2%;
}
.footer-social-links .link img {
  width: 20px; /* Set the width of the images to 20 pixels */
  height: 20px;

}

// 


//////   Amenities Component!!!! ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// 

.amenities-component {
  width: 94%;
  margin: auto;

}
.amenities-component h1 {
  width: 94%;
  margin: auto;
  text-align: center;
  color: white;
  font-size: 300%;
}

.amenities-gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  
}
.amenities-gallery .gallery-cont {
  width: 40%;
  height: 50%;
  // display: flex;
  margin: 4% 5%;
  opacity: 0;
  transition: .5s;
}
.amenities-gallery .gallery-cont.active {
  opacity: 1;
}

.amenities-gallery .gallery-cont img {
  width: 100%;
  height: 400px;
  // display: flex;
  border-radius: 20px;
}
.amenities-gallery .gallery-cont h3{

  font-size: 150%;
  color: rgb(255, 255, 255) ;

}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// 
/// 


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PARTNER COMPONENT!!!   / 
/// 

.Partner-section {
  width: 100%;
  margin: auto;
}

.Partner-section .about-intro {
  height: 55vh;
  position: absolute;
   z-index: 5 ;
  top: 0px;
  width: 100%;
  background-color: rgba(214, 30, 30, 0);
  color: white;

 }
 .Partner-section .about-intro {
  height: 55vh;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden; /* Hide any content that exceeds the container */
}
.Partner-section .about-intro img {
  height: 100%;
  filter: brightness(80%);
  position: relative;
  z-index: -111;
  // z-index: 1; 
  top: 0px;
  width: 100%;
 
 
 }
 .Partner-section .about-intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25%; /* Adjust this value to define the height of the top portion */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.367) 0%, rgba(0, 0, 0, 0.128) 100%);
  mix-blend-mode: multiply;
  border: none;
}
.Partner-section .about-intro h1 {
  margin-top: -16.5%;
  text-align: center;
  font-size: 320%;
  z-index: 1;
    // position: absolute;

 }
.Partner-section .about-intro h3 {
  font-size: 160%;
text-align: center;
line-height: .5;

 }
.about-summary {
  position: relative;
  margin-top: 24%;
}
.about-summary p{
  position: relative;
  width: 94%;
  margin: auto;
  font-size: 160%;
  font-weight: 500;
  color: rgb(255, 255, 255);
  margin-top: 3%;
  line-height: 2;
  // margin: 10% 0;
}


.faq-section {
  width: 94%;
  margin: auto;
  display: flex;
  margin-top: 7%;
  position: relative;
}

.faq-section .faq-info-section {
 background-color: white;
  width: 50%;
  height: 1250px;
  left: 100px;
  position: relative;
  z-index: 100;
  opacity: 0;
  transform: translateX(-50px);
  transition: .5s;
  border-radius: 20px;
}
.faq-section.active .faq-info-section {
  opacity: 1;
  transform: translateX(0px);
}
.faq-section .faq-info-section .faq-info {
// line-height: 2;
margin: auto;
  margin: 5% 10%;

}
.faq-section .faq-info-section .faq-info h1 {
line-height: 1.5;
font-size: 285%;
}
.faq-section .faq-info-section .faq-info h2 {
line-height: 2;
font-size: 160%;
}
.faq-section .faq-info-section .faq-info h3 {
line-height: 1.5;
font-size: 120%;
margin-bottom: 12%;
}
.faq-section .faq-img {
  width: 60%;
  position: relative;
  right: 100px;
  opacity: 0;
  transform: translateX(50px);
  transition: .75s;
}
.faq-section.active .faq-img {
  opacity: 1;
  transform: translateX(0px);
    border-radius: 20px;
}
.faq-section .faq-img img {
  width: 100%;
  height: 93%;
    filter: brightness(90%);
  border-radius: 20px;
}
.faq-section .faq-imgm {
  width: 85%;
  margin-top: 50px;
  display: none;
}
.faq-section .faq-imgm img {
  width: 90%;
  height: 93%;
  position: relative;
  right: 0px;
  left: 13px;
  border-radius: 20px;
  filter: brightness(90%);
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.img-banner {
  width: 100%;
  height: 550px;
  margin-top: 7%;
  margin-bottom: 3%;
}
.img-banner img{
  width: 100%;
  height: 100%;
  bottom: -5px;
  filter: brightness(70%);
}

.img-banner h1 {
 position: relative;
 bottom: 320px;
 margin: auto;
 text-align: center;
 color: white;
 font-size: 300%;

}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



////////{ CONTACT Component!!!!!  }//////////////////////////////////////////////////////////////////////////////////////////////////////////////

.ReachOut-component {  
  width: 94%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 8%;
}
.reachout-content {
  display: flex;
}
.ReachOut-component .contact-info {  
  width: 45%;
  margin: auto;

}
.ReachOut-component .contact-info h2 {  
  width: 100%;
  font-size: 180%;
  color: rgb(255, 255, 255) ;
}
.ReachOut-component .contact-info h3 {  
  width: 100%;
  line-height: 1.5;
  color: rgb(255, 255, 255) ;
}
.ReachOut-component .contact-info h4 {  
  width: 80%;
  line-height: 1.5;
  color: rgb(255, 255, 255) ;
  
}
 .bold-font {
  font-weight: 900;
 }
 .map-section {
  width: 50%;
  background-color: white;
  height: 400px; /* Set the desired height for the map */
  margin-left: auto;
  margin-right: auto;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////






////////   REQUEST COMP //////////////////////////////////////////////////////////////////////////////////////////////////////////////

.Request-component {

}



.Request-component .request-intro {
  height: 60vh;
  position: absolute;
   z-index: 5 ;
  top: 0px;
  width: 100vw;
  background-color: rgba(214, 30, 30, 0);
  color: white;

 }
.Request-component .request-intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15%; /* Adjust this value to define the height of the top portion */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
  mix-blend-mode: multiply;
}
.form-title { 
position: relative;
top: 400px;
font-size: 300%;
text-align: center;
color: white;
}
.Request-component .request-intro img {
  height: 100%;
  filter: brightness(70%);
  position: relative;
  z-index: -111;
  // z-index: 1; 
  top: 0px;
  width: 100vw;
 
 
 }
.Request-component .request-intro h1 {
  margin-top: -18%;
  text-align: center;
  font-size: 450%;
  z-index: 1;
    // position: absolute;

 }
.Request-component .request-intro h3 {
  font-size: 160%;
text-align: center;
line-height: .5;
font-weight: 400;
 }

 .Request-component form {
  margin-top: 30%;
  color: white;
 }


.request-form {
  display: flex;
  flex-direction: column;
  // max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.request-form .form-group {

  width: 30%;
  margin: auto;
  margin-bottom: 20px;
}

.request-form label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.request-form input[type="text"],
.request-form input[type="date"],
.request-form input[type="number"],
.request-form input[type="tel"],
.request-form input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.request-form button {
  background-color: #4caf50;
  background-color: rgb(83, 81, 81);
  background-color:	rgb(255,215,0);

  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: .25s;
  width: 30%;
  margin: auto;
  position: relative;
  left: .70%;
}
.request-form button:hover {
  background-color: #000000;
  color: white;

  cursor: pointer;
  border-radius: 4px;
}

.request-img-banner {
  margin-top: 40px;
}

.request-img-banner img {
  width: 300px;
}

.request-img-banner h1,
.request-img-banner h2 {
  margin-bottom: 10px;
}

.request-img-banner {
  width: 100%;
  height: 550px;
  margin-top: 7%;
  margin-bottom: 3%;
}
.request-img-banner img{
  width: 100%;
  height: 100%;
// overflow-y: 130px ;
  position: relative;
  filter: brightness(65%);
}

.request-img-banner h1 {
 position: relative;
 bottom: 320px;
 margin: auto;
 text-align: center;
 color: white;
 font-size: 300%;
}
.request-img-banner h2 {
 position: relative;
 bottom: 270px;
 margin: auto;
 text-align: center;
 color: white;
 font-size: 150%;
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// MOBILE MEDIA BELOW !!!!!!!             ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// MOBILE MEDIA BELOW !!!!!!!    /////// MOBILE MEDIA BELOW !!!!!!!    /////// MOBILE MEDIA BELOW !!!!!!!    /////// MOBILE MEDIA BELOW !!!!!!!    /////// MOBILE MEDIA BELOW !!!!!!!    /////// MOBILE MEDIA BELOW !!!!!!!    
/// /////// MOBILE MEDIA BELOW !!!!!!!    /////// MOBILE MEDIA BELOW !!!!!!!    /////// MOBILE MEDIA BELOW !!!!!!!    
/// media start

@media(max-width: 950px ) {
      
  html {
    scroll-behavior: smooth;
    background-color: rgb(28, 82, 84);
  }
  // /* Hide the scrollbar */
  // body::-webkit-scrollbar {
  //   width: 0.5em;
  //   background-color: blue;
  // }
  
  // /* Track */
  // body::-webkit-scrollbar-track {
  //   background: red;
  // }
  
  // /* Handle */
  // body::-webkit-scrollbar-thumb {
  //   background: blue;
  // }
  
  // /* Handle on hover */
  // body::-webkit-scrollbar-thumb:hover {
  //   background: transparent;
  // }
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    text-decoration: none;
    list-style: none;
    background-color: rgb(28, 82, 84);
  }
  
  body img {
    object-fit: cover;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  
  .App {
    width: 100%;
    transition: .2s;
    background-color: rgb(123, 156, 158);
    height: 2000%;
    background-color: rgb(28, 82, 84);
    overflow-x: hidden;
  }
  
  .navbar {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center; /* Centers the content vertically */
    height: 13vh;
      z-index: 100;
      position: relative;
  }
  
  .WordMark {
    margin-left: 5%; 
    bottom: 0%;
    position: relative;
    color: white;
    width: 60%;
  }
  .WordMark h1 {
    color: white;
    font-size: 190%;
  }
  .WordMark h1 a {
    color: white;
    text-decoration: none;
  }
  
  .nav-right-section {
    width: 60%;
    margin-left: 20%;
    display: none;
  }
  .nav-right-section ul {
    display: flex;
    justify-content: center; /* Centers the list horizontally */
    list-style: none; /* Removes the default list appearance */
  }
  
  .nav-right-section ul li {
    margin: 0 4%;
  }
  
  .nav-right-section ul li a {
    text-decoration: none;
    color: white;
    font-size: 120%;
    transition: .5s;
  }
  .nav-right-section ul li .clicked {
    text-decoration: none;
    border-bottom: 1.5px solid white;
    color: white;
    font-size: 120%;
    transition: .5s;
  }
  .nav-right-section ul li a:hover {
    text-decoration: none;
    border-bottom: 1.5px solid white;
    // color: rgb(5, 5, 5);
    font-size: 130%;
  }
  
  .social-links {
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
   right: 1.5%;
   position: relative;
   display: none;
  }
  
  .link {
    flex: 1; /* Distributes the available space equally among the child elements */
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    margin: 0 13%;
  }
  .link img {
    width: 20px; /* Set the width of the images to 20 pixels */
    height: 20px;
  
  }

  .request-btn {
    margin-left: 0%;
    width: 18%;
    position: relative;
    right: 0;
    display: none;
  }
  .request-btn a {
  padding: 17px 25px;
  background-color: rgb(83, 81, 81);
  color: white;
  position: relative;
  left: 30px;
  border: none;
  text-decoration: none;
  }
  .request-btn a:hover {
  
  padding: 17px 25px;
  background-color: gray;
  
  }
  

  /////
  
  .hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 12.5px;
    width: 25px;
    cursor: pointer;
    transition: .2s;
    position: absolute;
    right: 30px; 
    // top: 10px;
    margin: auto;
}
.hamburger.active .bar:nth-child(1) {
    transform: translateY(6px) rotate(135deg);
}
.hamburger.active .bar:nth-child(2) {
    transform: translateY(-5px) rotate(-135deg);
}
.hamburger.active .bar:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);

}
.bar {
    background: rgb(255,215,0);
    height: 2px;
    width: 130%;
    border-radius: 50px;
    transition: .2s;
    // border: .1px solid white;
}

.mediaNavPopUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 100%;
  left: 0px;
  //height: 15%;
  width: 100%;
  border: none;
  border-radius: 0px;
  // background: rgba(75, 75, 75, 0.521);
  background-color: rgb(28, 82, 84);
  backdrop-filter:blur(17.5px);
  transition: .5s;
  opacity: 0;
height: 100%;
}
.mediaNavPopUp.active {
  top: 12.5%;
  // position: fixed;
  opacity: 1;
  //display: content;
  position: fixed;
}

.mediaNavContent {
  width: 100%;
  display: block;
  margin-top: 0%;
// height: 88vh;
}

.mediaNavContent ul{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: auto;
  padding-top: 10%;
  padding-bottom: 00%;
}

.mediaNavContent ul li{
  list-style: none;
justify-content: center;
position: relative;
right: 38px;
  margin: auto;
  margin-top: 30px;
}

.mediaNavContent ul li a {
  color: white;
  font-size: 30px;
  // font-family: bold;
  display: block;
  text-decoration: none;

}
.mediaNavPopUp a {
  display: block;
  width: 90%;
}
.msocial-links {
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
 right: 1.5%;
 position: relative;
//  top: 10%;
 bottom: -15px;
//  display: none;
}

.mlink {
  flex: 1; /* Distributes the available space equally among the child elements */
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  margin: 0 13%;
}
.mlink img {
  width: 25px; /* Set the width of the images to 20 pixels */
  height: 25px;

}
.mediaNavPopUp button {
  color: rgb(0, 0, 0);
  // background: linear-gradient(90deg,#1bd3d8,#1ca8d9);
  padding: 13px 32.5px;
  border-radius: 7.5px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  margin-top: 0.5px;
  margin-bottom: 37.5px;
  position: relative;
  bottom: 75px;
  width: 100%;
  background-color:	rgb(255,215,0);
}
  
  // NAVBAR ABOVE ^^^^^^^^^^ /////////////////////////////////////////////////////////////////////
  
  
  
  
  ///// LANDING CONTENT !!!!  ///////////////////////////////////////////////// ///////////
  .Landing-content {
    width: 95%;
    margin: auto;
    color: white;
    margin-right: 0;
  }
  
  .Landing-content {
    width: 95%;
    margin: auto;
    color: white;
  
  }
  
  .Landing-content title-div  {
  width: 50%;
  
  }
  .Landing-content .title-div h1 {
  font-size: 45px;
  line-height: .9;
  font-weight: 650;
  margin-left: 2%;
  margin-bottom: 13%;
  line-height: 1;
  }
  
  
  .Landing-content .landing-image {
   width: 95%;
   margin: auto;
   height: auto;
   //height: 800px; /* Set the desired height for the container */
   overflow: hidden; /* Hide the overflowing content */
  }
  .Landing-content .landing-image img{
   width: 90%;
   display: block;
   width: 100%; /* Ensure the image fills the container horizontally */
   object-fit: cover; /* Crop the image to cover the container */
   position: relative;
   bottom: 30px ;
  }
  
  
  ////////@at-root
  /// 
  
  .Comfort-section {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
  }
  
  .Comfort-section .comfort-img {
    width: 100%;
    margin: auto;
  }
  .Comfort-section .comfort-img img{
    width: 100%;
    margin: auto;
  }
  .Comfort-section .comfort-info {
    width: 90%;
    margin: auto;
    margin-top: 10%;
  }
  .Comfort-section .comfort-info h2 {
  color: rgb(255, 255, 255);
  font-size: 215%;
  width: 90%;
  margin: auto;
  line-height: 1.2;
  }
  .Comfort-section .comfort-info p {
  color: rgb(255, 255, 255);
  font-size: 140%;
  width: 90%;
  margin: auto;
  margin-top: 5%;
  font-weight: 500;
  line-height: 2;
  }
  .Comfort-section .comfort-info p span {
  color: rgb(255,215,0);
  font-weight: 770;
  }
  
  .City-section {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25%;
  }
  
  .City-section .city-img {
    width: 100%;
    margin: auto;
    position: relative; /* Add relative positioning to the container */
    display: none;
  }
  .City-section .city-imgm {
    width: 100%;
    margin: auto;
    position: relative; /* Add relative positioning to the container */
    display: contents;
  }
  
  .City-section .city-imgm::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05); /* Set the background color with transparency */
  }
  
  .City-section .city-imgm img {
    width: 100%;
    margin: auto;
    position: relative; /* Add relative positioning to the image */
    border-radius: 20px;
  }
  .City-section .city-info {
    width: 90%;
    margin: 0;
    margin-top: 8%;
    margin-bottom: 50px;
  }
  .City-section .city-info h2 {
    color: rgb(255, 255, 255);
    font-size: 215%;
    width: 90%;
    margin: auto;
    line-height: 1.2;
    color: rgb(255,215,0);
  }
  .City-section .city-info p {
    color: rgb(255, 255, 255);
    font-size: 140%;
    width: 90%;
    margin: auto;
    margin-top: 5%;
    font-weight: 500;
    line-height: 1;
  }
  
  // AMENITIES section !!!! ///////////////////////////////////////
  
  .Amenities-section {
    width: 95%;
    height: 70vh;
    margin: auto;
    margin-top: 19%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Amenities-section .amenities-img {
    width: 90%;
    margin: auto;
    height: 100%; /* Set the desired height for the container */
    overflow: hidden; /* Hide the overflowing content */
    margin-right: 8%;
    position: absolute;
  }
  .Amenities-section .amenities-img img {
    width: 100%;
    margin: auto;
    position: relative;
    bottom: 25%;
    top: 0px;
    left: -2px;
    
  }
  
  .Amenities-section .amenities-content-box {
    background-color: white;
    height: 220px;
    width: 75%;
    position: relative;
    top: 140px;
    left: 15px;
    padding: 4.5%;
    line-height: .8;
    justify-content: center;
  }
  .Amenities-section .amenities-content-box h1 {
    font-size: 260%;
    position: relative;
    line-height: 1.1;
    top: -30px;
    // left: 15px;
  }
  .Amenities-section .amenities-content-box h3 {
    font-size: 150%;
    font-weight: 300;
    position: relative;
    line-height: 1.1;
    top: -40px;
    // left: 15px;
  }
  .Amenities-section .amenities-content-box button {
    font-size: 120%;
    padding: 20px 30px;
    background-color: rgb(83, 81, 81);
    color: white;
    border: none;
    position: relative;
    line-height: 1.1;
    top: -40px;
  }
  
  
  // Footer!! // ////////////////////////////////////@at-root
  
  .Footer {
    width: 95%;
    margin: auto;
    height: 25vh;
  }
  .Footer h1 {
   font-size: 250%;
   color: white;
  }
  
  .footer-social-links {
    display: flex;
    justify-content: left; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
   right: 0%;
   position: relative;
   width: 20%;
   top: 5%;
  }
  
  .footer-social-links .link {
    flex: 0; /* Distributes the available space equally among the child elements */
    display: flex;
    justify-content: left; /* Centers the content horizontally */
    margin: 0 2%;
  }
  .footer-social-links .link img {
    width: 20px; /* Set the width of the images to 20 pixels */
    height: 20px;
  
  }
  
  
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// 
  
  .amenities-component {
    width: 95%;
    margin: auto;
  
  }
  .amenities-component h1 {
    width: 94%;
    margin: auto;
    text-align: center;
    color: white;
    font-size: 250%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .amenities-gallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    
  }
  .amenities-gallery .gallery-cont {
    width: 90%;
    height: 50%;
    // display: flex;
    margin: 4% 5%;
  
  }
  .amenities-gallery .gallery-cont img {
    width: 100%;
    height: 400px;
    // display: flex;
  
  }
  .amenities-gallery .gallery-cont h3{
  
    font-size: 150%;
    color: rgb(255, 255, 255) ;
  
  }
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// 
  /// 
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PARTNER COMPONENT!!!   / 
  /// 
  
  .Partner-section {
    width: 100%;
    margin: auto;
  }
  
  .Partner-section .about-intro {
    height: 55vh;
    position: absolute;
     z-index: 5 ;
    top: 0px;
    width: 100vw;
    background-color: rgba(214, 30, 30, 0);
    color: white;
  
   }
  
  .Partner-section .about-intro img {
    height: 100%;
    filter: brightness(55%);
    position: relative;
    z-index: -111;
    // z-index: 1; 
    top: 0px;
    width: 100vw;
   
   
   }
  .Partner-section .about-intro h1 {
    margin-top: -15%;
    width: 100%;
    text-align: center;
    font-size: 220%;
    z-index: 1;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);      
   }
  .Partner-section .about-intro h3 {
    font-size: 120%;
  text-align: center;
  line-height: 2;
  width: 80%;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);      
   }
  .about-summary {
    position: relative;
    margin-top: 48vh;
  }
  .about-summary p{
    position: relative;
    width: 85%;
    margin: auto;
    font-size: 120%;
    font-weight: 500;
    color: rgb(255, 255, 255);
    margin-top: 7%;
    line-height: 2;
    // margin: 10% 0;
  }
  
  
  .faq-section {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7%;
  }
  .faq-section .faq-info-section {
   background-color: white;
    width: 90%;
    // right: 20px;
    height: auto;
    left: -10px;
    position: relative;
    z-index: 100;
    bottom: 75px;
  }
  .faq-section .faq-info-section .faq-info {
  // line-height: 2;
  margin: auto;
    margin: 5% 10%;
  
  }
  .faq-section .faq-info-section .faq-info h1 {
  line-height: 1.5;
  font-size: 225%;
  }
  .faq-section .faq-info-section .faq-info h2 {
  line-height: 1.5;
  font-size: 155%;
  }
  .faq-section .faq-info-section .faq-info h3 {
  line-height: 1.5;
  font-size: 110%;
  margin-bottom: 12%;
  }
  .faq-section .faq-img {
    width: 100%;
    position: relative;
    right: 0px;
    margin-top: 50px;
    display: none;
  }
  .faq-section .faq-img img {
    width: 100%;
    height: 93%;
    
  }
  
  .faq-section .faq-imgm {
    width: 85%;
    margin-top: 50px;
    display: contents;
  }
  .faq-section .faq-imgm img {
    width: 90%;
    height: 93%;
    position: relative;
    right: 0px;
    left: 13px;
    border-radius: 20px;
    filter: brightness(90%);
  }
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  .img-banner {
    width: 100%;
    height: 450px;
    margin-top: 7%;
    margin-bottom: 3%;
  }
  .img-banner img{
    width: 100%;
    height: 100%;
    bottom: -5px;
    filter: brightness(75%);
  }
  
  .img-banner h1 {
   position: relative;
   bottom: 270px;
   margin: auto;
   text-align: center;
   color: white;
   font-size: 215%;
  }
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
  
  ////////{ CONTACT Component!!!!!  }//////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  .ReachOut-component {  
    width: 95%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 8%;
  }
  .reachout-content {
    display: flex;
    flex-direction: column;
    align-items: center
    ;
  }
  .ReachOut-component .contact-info {  
    width: 100%;
    margin: 0;
  
  }
  .ReachOut-component .contact-info h2 {  
    width: 100%;
    font-size: 180%;
  }
  .ReachOut-component .contact-info h3 {  
    width: 100%;
    line-height: 1.5;
  }
  .ReachOut-component .contact-info h4 {  
    width: 80%;
    line-height: 1.5;
  }
   .bold-font {
    font-weight: 900;
   }
  
   .map-section {
    width: 90%;
    margin: auto;
    background-color: white;
    height: 300px; /* Set the desired height for the map */
    margin-left: auto;
    margin-right: auto;
    display: none;
  }
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
  
  
  
  
  ////////   REQUEST COMP //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  .Request-component {
  
  }
  
  
  
  .Request-component .request-intro {
    height: 60vh;
    position: absolute;
     z-index: 5;
    top: 0px;
    width: 100vw;
    background-color: rgba(214, 30, 30, 0);
    color: white;
  
   }
  
  .Request-component .request-intro img {
    height: 100%;
    filter: brightness(55%);
    position: relative;
    z-index: -111;
    // z-index: 1; 
    top: 0px;
    width: 100vw;
   
   
   }
  .Request-component .request-intro h1 {
    text-align: center;
    font-size: 225%;
    z-index: 1;
    bottom: 200px ;
    position: relative;
   }
  .Request-component .request-intro h3 {
    font-size: 160%;
    text-align: center;
    line-height: 1.5;
    bottom: 200px ;
    position: relative;
   }
  
   .Request-component form {
    margin-top: 115%;
    width: 100%;
   }
  
   .form-title { 
    position: relative;
    top: 390px;
    font-size: 210%;
    margin: auto;
    width: 90%;
    text-align: center;
    color: white;
    }
  .request-form {
    display: flex;
    flex-direction: column;
    // max-width: 400px;
    width: 100%;
    margin: auto;
    position: relative;
    right: 10px;
  }
  
  .request-form .form-group {
    width: 75%;
    margin: auto;
    margin-bottom: 15px;
  }
  
  .request-form label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .request-form input[type="text"],
  .request-form input[type="date"],
  .request-form input[type="number"],
  .request-form input[type="tel"],
  .request-form input[type="email"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .request-form button {
    background-color: #4caf50;
    background-color: rgb(83, 81, 81);
    background-color:	rgb(255,215,0);
  margin-left: 3%;
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin: auto;
    left: 10px;
  }
  .request-form button:hover {
    background-color: #000000;
    color: white;
  
    cursor: pointer;
    border-radius: 4px;
  }
  
  .request-img-banner {
    margin-top: 40px;
  }
  
  .request-img-banner img {
    width: 300px;
  }
  
  .request-img-banner h1,
  .request-img-banner h2 {
    margin-bottom: 10px;
  }
  
  .request-img-banner {
    width: 100%;
    height: 400px;
    margin-top: 25%;
    margin-bottom: 3%;
  }
  .request-img-banner img{
    width: 100%;
    height: 100%;
  overflow-y: 50px ;
    position: relative;
    filter: brightness(55%);
  }
  
  .request-img-banner h1 {
   position: relative;
   bottom: 320px;
   margin: auto;
   text-align: center;
   color: white;
   font-size: 250%;
  }
  .request-img-banner h2 {
   position: relative;
   bottom: 270px;
   margin: auto;
   text-align: center;
   color: white;
   font-size: 150%;
  }


}